import React, { useEffect } from 'react'
import {
    UserOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {reset} from '../../redux/reducers/authSlice'
import { useNavigate } from 'react-router-dom';
import { Layout, Button, Avatar, Typography } from 'antd';
import { getUserProfile, logout } from '../../redux/actions/authAction';
import axios from 'axios';
const { Text } = Typography;

const { Header } = Layout;

const DashboardHeader = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)

    const onLogout = () => {
      dispatch(logout())
      dispatch(reset())
      navigate('/')
    }

    useEffect(() => {
        if(localStorage.getItem("user")){
            let user = JSON.parse(localStorage.getItem("user"))
            dispatch(getUserProfile(user))
        }
    }, [])
    

    return (
        <Header className="site-layout-background header">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '.5rem' }}>
                <Avatar icon={<UserOutlined />} />
                <Text type='primary'>Hi, <span className="capitalize">{user?.fName+" "+user?.lName}</span></Text>
            </div>
            <Button danger style={{ marginRight: '2rem' }} onClick={onLogout}>Logout</Button>
        </Header>
    )
}

export default DashboardHeader;