import React from 'react'

const Home = () => {
  return (
    <div className="tableWrapper" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
      {/* <div className="categoryHeader" > */}
        {/* <Title level={2}>
          Githubs
        </Title>
        <Space>
          <Search
            placeholder="Search github"
            allowClear
            size="large"
            onSearch={(value) => setSearchedText(value)}
            onChange={(e) => setSearchedText(e.target.value)}
            style={{
              width: 300,
            }}
            className="searchBar"
          />
          <Button
            className="btn-bg"
            // disabled={isLoading}
            type="primary"
            size="large"
            onClick={() => handleSave()}
          >
            Add Github
          </Button>
        </Space>

        {showGithubModal && (
          <GithubModal
            show={showGithubModal}
            close={() => {
              setShowGithubModal(false);
              setGithubData(null);
            }}
            data={githubData}
          />
        )} */}
      {/* </div> */}
        {/* <h1 style={{fontSize: "2.5rem"}}>Coming Soon</h1> */}
        <img src="/coming soon.gif" alt="" style={{width: "100%", height: "100%"}} />
      {/* <Table
        // loading={isLoading}
        columns={columns}
        dataSource={data}
        pagination={{ defaultPageSize: 5 }}
        className="table"
        scroll={{ x: 100 }}
      /> */}
    </div>
  )
}

export default Home