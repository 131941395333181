import { useState, useEffect } from "react";
import {
  ExclamationCircleOutlined,
  InteractionOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Input,
  Space,
  Spin,
  Popconfirm,
  Tooltip,
  Table,
  Modal,
  Button,
} from "antd";
import { FaPenSquare, FaTrash, FaTrashAlt } from "react-icons/fa";
import EmailModal from "../modals/EmailModal";
import { deleteEmail, getEmails } from "../redux/actions/emailAction";
import { useDispatch, useSelector } from "react-redux";
import { reset, resetEmail } from "../redux/reducers/emailSlice";
const { Title } = Typography;
const { Search } = Input;

const Email = () => {
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [emailData, setEmailData] = useState();
  const [isPass, setIsPass] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const dispatch = useDispatch();
  const { emails, isLoading } = useSelector((state) => state.email);

  useEffect(() => {
    if(localStorage.getItem("user")){
      dispatch(getEmails(JSON.parse(localStorage.getItem("user"))));
    }
  }, []);

  const handleEdit = (data) => {
    setEmailData(data);
    setShowEmailModal(true);
  };

  const handleSave = () => {
    setShowEmailModal(true);
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to delete this email?",
      okText: "Delete",
      cancelText: "Cancel",
      okType: "danger",
      onOk: () => {
        dispatch(deleteEmail(id));
      },
    });
  };

  const columns = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      visible: true,
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return (
          String(record.name).toLowerCase().includes(value.toLowerCase()) ||
          String(record.email).toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Recovery Email",
      dataIndex: "recoveryEmail",
      key: "recoveryEmail",
    },
    {
      title: "Recovery Phone No",
      dataIndex: "recoveryPhone",
      key: "recoveryPhone",
    },
    {
      title: "Password",
      dataIndex: "password",
      key: "password",
      render: (_, record) => {
        return (
          <>
            <Input.Password
              value={record.password}
              style={{
                border: "none",
                outline: "none",
                background: "white",
                width: "fit-content",
              }}
            />
          </>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space>
          <Button size="small" type="text" style={{ color: "blue" }}>
            <Tooltip title="edit">
              <FaPenSquare onClick={() => handleEdit(record)} />
            </Tooltip>
          </Button>
          <Button
            size="small"
            type="text"
            danger
            onClick={() => handleDelete(record.key)}
          >
            <Tooltip title="delete">
              <FaTrash />
            </Tooltip>
          </Button>
        </Space>
      ),
    },
  ];

  const data = [];
  emails?.map((email, i) => {
    data.push({
      key: email._id,
      no: i + 1,
      name: email.name,
      email: email.email,
      password: email.password,
      recoveryEmail: email.recoveryEmail,
      recoveryPhone: email.recoveryPhone,
    });
  });

  return (
    <div className="tableWrapper">
      <div className="categoryHeader">
        <Title level={2}>Emails</Title>
        <Space>
          <Search
            placeholder="Search email"
            allowClear
            size="large"
            onSearch={(value) => setSearchedText(value)}
            onChange={(e) => setSearchedText(e.target.value)}
            style={{
              width: 300,
            }}
            className="searchBar"
          />
          <Button
            className="btn-bg"
            // disabled={isLoading}
            type="primary"
            size="large"
            onClick={() => handleSave()}
          >
            Add Email
          </Button>
        </Space>

        {showEmailModal && (
          <EmailModal
            show={showEmailModal}
            close={() => {
              setShowEmailModal(false);
              setEmailData(null);
              dispatch(resetEmail())
            }}
            data={emailData}
          />
        )}
      </div>
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={data}
        pagination={{ defaultPageSize: 5 }}
        className="table"
        scroll={{ x: 100 }}
      />
    </div>
  );
};

export default Email;
