import { configureStore } from "@reduxjs/toolkit";
import email from "../redux/reducers/emailSlice";
import auth from "../redux/reducers/authSlice";
import youtube from "../redux/reducers/youtubeSlice";

export const store = configureStore({
  reducer: {
    auth,
    email,
    youtube,
  },
});
