import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaEnvelope, FaFacebook, FaGithub, FaHome, FaMicrosoft, FaShoppingBag, FaTwitter, FaYoutube } from "react-icons/fa";
import { Layout, Menu } from "antd";

const { Sider } = Layout;
const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  let location = useLocation();
  const [current, setCurrent] = useState(location.pathname);

  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname);
      }
    }
  }, [location, current]);
  const items = [
    { label: "Dashboard", key: "/dashboard", icon: <FaHome /> },
    { label: "Email", key: "/dashboard/email", icon: <FaEnvelope /> },
    { label: "Youtube", key: "/dashboard/youtube", icon: <FaYoutube /> },
    { label: "Twitter", key: "/dashboard/twitter", icon: <FaTwitter /> },
    { label: "Facebook", key: "/dashboard/facebook", icon: <FaFacebook /> },
    { label: "Github", key: "/dashboard/github", icon: <FaGithub /> },
    { label: "Microsoft", key: "/dashboard/microsoft", icon: <FaMicrosoft /> },
  ];

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => {
        setCollapsed(value);
      }}
      className="sidebar"
    >
      <div className="logo" id="logo" style={{ fontSize: collapsed ? ".5rem" : "1rem"}}>
        Save Me
      </div>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={["/dashboard"]}
        onClick={({ key }) => {
          navigate(key);
          setCurrent(key);
        }}
        items={items}
        selectedKeys={current}
        style={{ height: "40rem" }}
      />
    </Sider>
  );
};

export default Sidebar;
