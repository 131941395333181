import { Bars } from "react-loader-spinner";

const NewLoader = ({message}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      <Bars
        height="80"
        width="80"
        color="#001529"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
      <p>{message}</p>
    </div>
  );
};

export default NewLoader;
