import { useState, useEffect } from "react";
import {
  ExclamationCircleOutlined,
  InteractionOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Input,
  Space,
  Spin,
  Popconfirm,
  Tooltip,
  Table,
  Modal,
  Button,
} from "antd";
import { FaPenSquare, FaTrash, FaTrashAlt } from "react-icons/fa";
import YoutubeModal from "../modals/YoutubeModal";
import { deleteYoutube, getYoutubes } from "../redux/actions/youtubeAction";
import { useDispatch, useSelector } from "react-redux";
import { reset, resetYoutube } from "../redux/reducers/youtubeSlice";
const { Title } = Typography;
const { Search } = Input;

const Youtube = () => {
  const [showYoutubeModal, setShowYoutubeModal] = useState(false);
  const [youtubeData, setYoutubeData] = useState();
  const [isPass, setIsPass] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const dispatch = useDispatch();
  const { youtubes, isLoading } = useSelector((state) => state.youtube);

  useEffect(() => {
    if (localStorage.getItem("user")) {
      dispatch(getYoutubes(JSON.parse(localStorage.getItem("user"))));
    }
  }, []);

  const handleEdit = (data) => {
    setYoutubeData(data);
    setShowYoutubeModal(true);
  };

  const handleSave = () => {
    setShowYoutubeModal(true);
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to delete this youtube?",
      okText: "Delete",
      cancelText: "Cancel",
      okType: "danger",
      onOk: () => {
        dispatch(deleteYoutube(id));
      },
    });
  };

  const columns = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Creator Name",
      dataIndex: "creatorName",
      key: "creatorName",
      visible: true,
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return (
          String(record.creatorName)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.channelId)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.channelName).toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      title: "Channel Name",
      dataIndex: "channelName",
      key: "channelName",
    },
    {
      title: "Channel Id",
      dataIndex: "channelId",
      key: "channelId",
    },
    {
      title: "Channel URL",
      dataIndex: "channelUrl",
      key: "channelUrl",
    },
    {
      title: "Password",
      dataIndex: "password",
      key: "password",
      render: (_, record) => {
        return (
          <>
            {record.password === "" ? (
              ""
            ) : (
              <Input.Password
                value={record.password}
                style={{
                  border: "none",
                  outline: "none",
                  background: "white",
                  width: "fit-content",
                }}
              />
            )}
          </>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space>
          <Button size="small" type="text" style={{ color: "blue" }}>
            <Tooltip title="edit">
              <FaPenSquare onClick={() => handleEdit(record)} />
            </Tooltip>
          </Button>
          <Button
            size="small"
            type="text"
            danger
            onClick={() => handleDelete(record.key)}
          >
            <Tooltip title="delete">
              <FaTrash />
            </Tooltip>
          </Button>
        </Space>
      ),
    },
  ];

  const data = [];
  youtubes?.map((youtube, i) => {
    data.push({
      key: youtube._id,
      no: i + 1,
      creatorName: youtube.creatorName,
      channelName: youtube.channelName,
      password: youtube.password,
      channelId: youtube.channelId,
      channelUrl: youtube.channelUrl,
    });
  });

  return (
    <div className="tableWrapper">
      <div className="categoryHeader">
        <Title level={2}>Youtube Channels</Title>
        <Space>
          <Search
            placeholder="Search youtube channel"
            allowClear
            size="large"
            onSearch={(value) => setSearchedText(value)}
            onChange={(e) => setSearchedText(e.target.value)}
            style={{
              width: 300,
            }}
            className="searchBar"
          />
          <Button
            className="btn-bg"
            // disabled={isLoading}
            type="primary"
            size="large"
            onClick={() => handleSave()}
          >
            Add Channel
          </Button>
        </Space>

        {showYoutubeModal && (
          <YoutubeModal
            show={showYoutubeModal}
            close={() => {
              setShowYoutubeModal(false);
              setYoutubeData(null);
              dispatch(resetYoutube());
            }}
            data={youtubeData}
          />
        )}
      </div>
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={data}
        pagination={{ defaultPageSize: 5 }}
        className="table"
        scroll={{ x: 500 }}
      />
    </div>
  );
};

export default Youtube;
