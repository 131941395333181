import axios from "axios";

const API_URL = 'http://localhost:5000/api'
// const API_URL = 'https://saveme-server.vercel.app/api'

// Get user token from localStorage
// const token = JSON.parse(localStorage.getItem('user'))

// const config = {
//   headers: { Authorization: `Bearer ${token}` }
// }

// Add New Youtube
const addYoutube = async (data) => {
  const response = await axios.post(`${API_URL}/postyoutube`, data)

  return response.data
}

// Get Single Youtube By Id
const getYoutube = async (id) => {

  const response = await axios.get(`${API_URL}/youtube/${id}`)

  return response.data
}

// Get All Youtubes
const getYoutubes = async (userId) => {
  const response = await axios.get(`${API_URL}/youtubes/${userId}`)

  return response.data
}

// Update Youtube
const updateYoutube = async (id, youtube) => {
  const response = await axios.put(`${API_URL}/updateyoutube/${id}`, youtube)

  return response.data
}


// Delete Youtube
const deleteYoutube = async (id) => {

  const response = await axios.delete(`${API_URL}/deleteyoutube/${id}`)

  return response.data
}


const youtubeApi = {
  addYoutube,
  getYoutube,
  getYoutubes,
  updateYoutube,
  deleteYoutube,
}

export default youtubeApi