import logo from "./logo.svg";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import DashboardLayout from "./pages/DashboardLayout";
import Email from "./pages/Email";
import Twitter from "./pages/Twitter";
import Facebook from "./pages/Facebook";
import Microsoft from "./pages/Microsoft";
import Github from "./pages/Github";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Protected from "./components/protected routes/Protected";
import Youtube from "./pages/Youtube";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/dashboard" element={<Protected Component={DashboardLayout} />}>
        <Route path="/dashboard" element={<Protected Component={Home} />} />
        <Route path="/dashboard/email" element={<Protected Component={Email} />} />
        <Route path="/dashboard/youtube" element={<Protected Component={Youtube} />} />
        <Route path="/dashboard/twitter" element={<Protected Component={Twitter} />} />
        <Route path="/dashboard/facebook" element={<Protected Component={Facebook} />} />
        <Route path="/dashboard/microsoft" element={<Protected Component={Microsoft} />} />
        <Route path="/dashboard/github" element={<Protected Component={Github} />} />
      </Route>
    </Routes>
  );
}

export default App;
