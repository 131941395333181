import { createSlice } from '@reduxjs/toolkit'
import { notification } from 'antd'
import { addYoutube, getYoutube, getYoutubes, deleteYoutube, updateYoutube } from '../actions/youtubeAction'

const initialState = {
    youtubes: [],
    youtube: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    singleYoutubeIsLoading: false,
    message: '',
}

export const youtubeSlice = createSlice({
    name: 'youtube',
    initialState,
    reducers: {
        reset: (state) => {
            state.youtubes = []
            state.youtube = null
            state.isError = false
            state.isSuccess = false
            state.isLoading = false
            state.singleYoutubeIsLoading = false
            state.message = ''
        },
        resetYoutube: (state) => {
            state.youtube = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(addYoutube.pending, (state) => {
                state.isLoading = true
            })
            .addCase(addYoutube.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.message = action.payload.message
                state.youtubes.push(action.payload.data)
                notification.open({
                    message: action.payload.message,
                    placement: "top",
                    type: "success"
                })
            })
            .addCase(addYoutube.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload.message
                notification.open({
                    message: "Youtube already added",
                    placement: "top",
                    type: "error"
                })
            })
            .addCase(getYoutube.pending, (state) => {
                state.singleYoutubeIsLoading = true
            })
            .addCase(getYoutube.fulfilled, (state, action) => {
                state.singleYoutubeIsLoading = false
                state.youtube = action.payload.message === 'No Youtube Found' ? null : action.payload
                state.message = action.payload.message
            })
            .addCase(getYoutube.rejected, (state, action) => {
                state.singleYoutubeIsLoading = false
                state.isError = true
                state.message = action.payload.message
            })
            .addCase(getYoutubes.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getYoutubes.fulfilled, (state, action) => {
                state.isLoading = false
                state.youtubes = action.payload.message === 'No Youtube Found' ? [] : action.payload
                state.message = action.payload.message
            })
            .addCase(getYoutubes.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload.message
            })
            .addCase(updateYoutube.pending, (state) => {
                state.isLoading = true
            })
            .addCase(updateYoutube.fulfilled, (state, action) => {
                const updatedYoutubes = state.youtubes.map((youtube) =>
                    youtube._id === action.payload._id ? action.payload.data : youtube
                );
                state.isLoading = false
                state.isSuccess = true
                state.youtubes = updatedYoutubes
                state.message = action.payload.message
                notification.open({
                    message: action.payload.message,
                    placement: "top",
                    type: "success"
                })
            })
            .addCase(updateYoutube.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(deleteYoutube.pending, (state) => {
                state.isLoading = true
            })
            .addCase(deleteYoutube.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.youtubes = state.youtubes.filter(
                    (youtube) => youtube._id !== action.payload.id
                )
                state.message = action.payload.message
                notification.open({
                    message: action.payload.message,
                    placement: "top",
                    type: "success"
                })
            })
            .addCase(deleteYoutube.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    }
})

// Action creators are generated for each case reducer function
export const { reset, resetYoutube } = youtubeSlice.actions

export default youtubeSlice.reducer