import { Button, Form, Input, Modal, Spin } from "antd";
import { Formik } from "formik";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addEmail, getEmail, updateEmail } from "../redux/actions/emailAction";
import { reset } from "../redux/reducers/emailSlice";

const EmailModal = (props) => {
  const dispatch = useDispatch();
  const { email, singleEmailIsLoading } = useSelector((state) => state.email);
  useEffect(() => {
    if (props?.data) {
      dispatch(getEmail(props?.data?.key));
    }
  }, []);

  const handleSubmit = (values) => {
    if (props?.data) {
      dispatch(
        updateEmail({
          id: props.data.key,
          email: values,
        })
      );
    } else {
      values.userId = JSON.parse(localStorage.getItem("user"))
      dispatch(addEmail(values));
    }
    props.close();
  };
  if (singleEmailIsLoading) {
    return;
  }
  return (
    <Modal
      title={props?.data ? "Edit Email" : "Add Email"}
      open={props.show}
      footer={null}
      onCancel={props.close}
    >
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <Form
          size="large"
          layout="vertical"
          initialValues={{
            name: email?.name || "",
            email: email?.email || "",
            password: email?.password || "",
            recoveryEmail: email?.recoveryEmail || "",
            recoveryPhone: email?.recoveryPhone || "",
          }}
          onSubmit={handleSubmit}
          // name="basic"
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
          }}
          onFinish={handleSubmit}
        >
          <Form.Item
            style={{ width: "100%" }}
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Please enter name",
              },
            ]}
          >
            <Input placeholder="Name" style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            style={{ width: "100%" }}
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: "Please enter email",
              },
            ]}
          >
            <Input
              type="email"
              placeholder="i.e. example@gmail.com"
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            style={{ width: "100%" }}
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please enter password",
              },
            ]}
          >
            <Input.Password placeholder="Password" style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            style={{ width: "100%" }}
            name="recoveryEmail"
            label="Recovery Email (Optional)"
          >
            <Input
              type="email"
              placeholder="Recovery email (if any)"
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            style={{ width: "100%" }}
            name="recoveryPhone"
            label="Recovery Phone No (Optional)"
          >
            <Input
              type="number"
              placeholder="Recovery phone no (if any)"
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Button
              className="btn-bg"
              size="large"
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default EmailModal;
