import { Button, Form, Input, Modal } from "antd";
import React from "react";

const TwitterModal = (props) => {
  return (
    <Modal
      title={props?.data ? "Edit Twitter Account" : "Add Twitter Account"}
      open={props.show}
      footer={null}
      onCancel={props.close}
    >
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <Form
          size="large"
          name="basic"
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
          }}
          //   onFinish={handleSubmit}
        >
          <Form.Item
            style={{ width: "100%" }}
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter name",
              },
            ]}
          >
            <Input placeholder="Name" style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            style={{ width: "100%" }}
            name="twitter"
            rules={[
              {
                required: true,
                message: "Please enter twitter id",
              },
            ]}
          >
            <Input
              type="email"
              placeholder="i.e. example@gmail.com"
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            style={{ width: "100%" }}
            name="password"
            rules={[
              {
                required: true,
                message: "Please enter password",
              },
            ]}
          >
            <Input
              type="password"
              placeholder="Password"
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Button
              className="btn-bg"
              size="large"
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default TwitterModal;
