import { useState, useEffect } from "react";
import {
  ExclamationCircleOutlined,
  InteractionOutlined,
} from "@ant-design/icons";
import { Typography, Input, Space, Spin, Popconfirm, Tooltip, Table, Modal, Button } from "antd";
import { FaPenSquare, FaTrash, FaTrashAlt } from "react-icons/fa";
// import GithubModal from "../modals/GithubModal";
const { Title } = Typography;
const { Search } = Input;

const Github = () => {
  const [showGithubModal, setShowGithubModal] = useState(false);
  const [githubData, setGithubData] = useState();
  const [searchedText, setSearchedText] = useState("");

  const handleEdit = (data) => {
    setGithubData(data);
    setShowGithubModal(true);
  };

  const handleSave = () => {
    setShowGithubModal(true);
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to delete this github?",
      okText: "Delete",
      cancelText: "Cancel",
      okType: "danger",
      onOk: () => {
        // dispatch(deleteGithub(id));
      },
    });
  };

  const columns = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      visible: true,
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return (
          String(record.name).toLowerCase().includes(value.toLowerCase())
        );
      }
    },
    {
      title: "Github",
      dataIndex: "github",
      key: "github",
    },
    {
      title: "Password",
      dataIndex: "password",
      key: "password",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space>
          <Button size="small" type="text" style={{ color: "blue" }}>
            <Tooltip title="edit">
              <FaPenSquare
                onClick={() => handleEdit(record)}
              />
            </Tooltip>
          </Button>
          <Button
            size="small"
            type="text"
            danger
            onClick={() => handleDelete(record.key)}
          >
            <Tooltip title="delete">
              <FaTrash />
            </Tooltip>
          </Button>
        </Space>
      ),
    },
  ]

  const data = [{
    no: 1,
    name: "Awais",
    github: "awais@gmail.com",
    password: "123"
  }]

  return (
    <div className="tableWrapper" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
      {/* <div className="categoryHeader" > */}
        {/* <Title level={2}>
          Githubs
        </Title>
        <Space>
          <Search
            placeholder="Search github"
            allowClear
            size="large"
            onSearch={(value) => setSearchedText(value)}
            onChange={(e) => setSearchedText(e.target.value)}
            style={{
              width: 300,
            }}
            className="searchBar"
          />
          <Button
            className="btn-bg"
            // disabled={isLoading}
            type="primary"
            size="large"
            onClick={() => handleSave()}
          >
            Add Github
          </Button>
        </Space>

        {showGithubModal && (
          <GithubModal
            show={showGithubModal}
            close={() => {
              setShowGithubModal(false);
              setGithubData(null);
            }}
            data={githubData}
          />
        )} */}
      {/* </div> */}
        {/* <h1 style={{fontSize: "2.5rem"}}>Coming Soon</h1> */}
        <img src="/coming soon.gif" alt="" style={{width: "100%", height: "100%"}} />
      {/* <Table
        // loading={isLoading}
        columns={columns}
        dataSource={data}
        pagination={{ defaultPageSize: 5 }}
        className="table"
        scroll={{ x: 100 }}
      /> */}
    </div>
  );
}

export default Github