import axios from "axios";

// const API_URL = 'http://localhost:5000/api'
const API_URL = 'https://saveme-server.vercel.app/api'

// Login user
const login = async (userData) => {
  const response = await axios.post(`${API_URL}/auth/login`, userData)

  if(response.data){
    localStorage.setItem('user', JSON.stringify(response.data.token))
  }
  return response.data
}

// Register user
const register = async (userData) => {
  const response = await axios.post(`${API_URL}/auth/signup`, userData)

  if(response.data){
    localStorage.setItem('user', JSON.stringify(response.data.token))
  }
  return response.data
}

// Get user
const getUserProfile = async (userData) => {
  const response = await axios.get(`${API_URL}/auth/profile/${userData}`)
  return response.data
}

// Logout user
const logout = () => {
  localStorage.removeItem('user')
}

const authApi = {
    login,
    getUserProfile,
    register,
    logout,
}

export default authApi