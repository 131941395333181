import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import NewLoader from "../Loaders/NewLoader";
// const API_URL = "http://localhost:5000/api";
const API_URL = 'https://saveme-server.vercel.app/api'
const Protected = ({ Component }) => {
  let user = JSON.parse(localStorage.getItem("user"));
  const [varified, setVerified] = useState(false);
  const navigate = useNavigate();
  const verify = async () => {
    await axios
      .post(`${API_URL}/auth/check/${user}`)
      .then((res) => {
        if (res.status === 200) {
          setVerified(true);
        }
        if (res.data === "token expired") {
          localStorage.removeItem("user");
          setVerified(false);
        }
      })
      .catch((error) => {
        localStorage.removeItem("user");
        window.location.replace("/");
      });
  };

  useEffect(() => {
    setInterval(() => {
      verify();
    }, 1000);
  }, []);

  return !user ? (
    <>
      <NewLoader message={"Please Wait..."} />
      {window.location.replace("/")}
    </>
  ) : varified ? (
    <Component />
  ) : (
    <>
      <NewLoader message={"Please Wait..."} />
    </>
  );
};

export default Protected;
