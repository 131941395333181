import { createAsyncThunk } from '@reduxjs/toolkit'
import youtubeApi from '../../api/youtubeApi'

// Add New Youtube
export const addYoutube = createAsyncThunk('youtube/add', async (data, thunkAPI) => {
    try {
        return await youtubeApi.addYoutube(data)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

// Get Single Youtube By Id
export const getYoutube = createAsyncThunk('youtube/single', async (id, thunkAPI) => {
    try {
        return await youtubeApi.getYoutube(id)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

// Get Youtubes
export const getYoutubes = createAsyncThunk('youtube/all', async (userId, thunkAPI) => {
    try {
        return await youtubeApi.getYoutubes(userId)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

// Update Youtube
export const updateYoutube = createAsyncThunk('youtube/update', async (data, thunkAPI) => {
    try {
        const { id, youtube } = data
        return await youtubeApi.updateYoutube(id, youtube)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

// Delete Youtube
export const deleteYoutube = createAsyncThunk('youtube/delete', async (id, thunkAPI) => {

    try {
        return await youtubeApi.deleteYoutube(id)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})