import { createSlice } from '@reduxjs/toolkit'
import { notification } from 'antd'
import { addEmail, getEmail, getEmails, deleteEmail, updateEmail } from '../actions/emailAction'

const initialState = {
    emails: [],
    email: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    singleEmailIsLoading: false,
    message: '',
}

export const emailSlice = createSlice({
    name: 'email',
    initialState,
    reducers: {
        reset: (state) => {
            state.emails = []
            state.email = null
            state.isError = false
            state.isSuccess = false
            state.isLoading = false
            state.singleEmailIsLoading = false
            state.message = ''
        },
        resetEmail: (state) => {
            state.email = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(addEmail.pending, (state) => {
                state.isLoading = true
            })
            .addCase(addEmail.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.message = action.payload.message
                state.emails.push(action.payload.data)
                notification.open({
                    message: action.payload.message,
                    placement: "top",
                    type: "success"
                })
            })
            .addCase(addEmail.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload.message
                notification.open({
                    message: "Email already added",
                    placement: "top",
                    type: "error"
                })
            })
            .addCase(getEmail.pending, (state) => {
                state.singleEmailIsLoading = true
            })
            .addCase(getEmail.fulfilled, (state, action) => {
                state.singleEmailIsLoading = false
                state.email = action.payload.message === 'No Email Found' ? null : action.payload
                state.message = action.payload.message
            })
            .addCase(getEmail.rejected, (state, action) => {
                state.singleEmailIsLoading = false
                state.isError = true
                state.message = action.payload.message
            })
            .addCase(getEmails.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getEmails.fulfilled, (state, action) => {
                state.isLoading = false
                state.emails = action.payload.message === 'No Email Found' ? [] : action.payload
                state.message = action.payload.message
            })
            .addCase(getEmails.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload.message
            })
            .addCase(updateEmail.pending, (state) => {
                state.isLoading = true
            })
            .addCase(updateEmail.fulfilled, (state, action) => {
                const updatedEmails = state.emails.map((email) =>
                    email._id === action.payload._id ? action.payload.data : email
                );
                state.isLoading = false
                state.isSuccess = true
                state.emails = updatedEmails
                state.message = action.payload.message
                notification.open({
                    message: action.payload.message,
                    placement: "top",
                    type: "success"
                })
            })
            .addCase(updateEmail.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(deleteEmail.pending, (state) => {
                state.isLoading = true
            })
            .addCase(deleteEmail.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.emails = state.emails.filter(
                    (email) => email._id !== action.payload.id
                )
                state.message = action.payload.message
                notification.open({
                    message: action.payload.message,
                    placement: "top",
                    type: "success"
                })
            })
            .addCase(deleteEmail.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    }
})

// Action creators are generated for each case reducer function
export const { reset, resetEmail } = emailSlice.actions

export default emailSlice.reducer