import { createAsyncThunk } from '@reduxjs/toolkit'
import emailApi from '../../api/emailApi'

// Add New Email
export const addEmail = createAsyncThunk('email/add', async (data, thunkAPI) => {
    try {
        return await emailApi.addEmail(data)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

// Get Single Email By Id
export const getEmail = createAsyncThunk('email/single', async (id, thunkAPI) => {
    try {
        return await emailApi.getEmail(id)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

// Get Emails
export const getEmails = createAsyncThunk('email/all', async (userId, thunkAPI) => {
    try {
        return await emailApi.getEmails(userId)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

// Update Email
export const updateEmail = createAsyncThunk('email/update', async (data, thunkAPI) => {
    try {
        const { id, email } = data
        return await emailApi.updateEmail(id, email)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

// Delete Email
export const deleteEmail = createAsyncThunk('email/delete', async (id, thunkAPI) => {

    try {
        return await emailApi.deleteEmail(id)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})