import { Button, Form, Input, Tabs } from "antd";
import { useEffect } from "react";
import { AiOutlineLogin } from "react-icons/ai";
import { FaUserPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loaders/Loader";
import { login, register } from "../redux/actions/authAction";
import { useNavigate } from "react-router-dom";
import { reset } from "../redux/reducers/authSlice";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, isSuccess } = useSelector((state) => state.auth);
  const user = localStorage.getItem("user");

  useEffect(() => {
    if (isSuccess || user) {
      navigate("/dashboard");
    }

    dispatch(reset());
  }, [user, isSuccess, dispatch, navigate]);
  const loginUser = (values) => {
    dispatch(login(values));
  };

  const signUp = (values) => {
    dispatch(register(values));
  };
  return (
    <div className="flex items-center justify-center w-full h-2/4 px-10 py-2">
      <div
        className="flex flex-row-reverse w-full px-10 py-10 gap-5 rounded-lg"
        style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
      >
        <div className="w-2/4 flex items-center justify-center">
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                label: (
                  <span className="flex items-center justify-center gap-1">
                    <AiOutlineLogin />
                    Login
                  </span>
                ),
                key: 1,
                children: (
                  <>
                    <h1 className="text-2xl font-bold text-center text-[#001529] font-mono">
                      Login
                    </h1>
                    <Form
                      size="large"
                      layout="vertical"
                      initialValues={{
                        email: "",
                        password: "",
                      }}
                      className="flex items-center flex-col w-full mt-2"
                      onFinish={loginUser}
                    >
                      <Form.Item
                        className="shadow-md w-full"
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Please enter email",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Email"
                          className="shadow-md w-full"
                        />
                      </Form.Item>
                      <Form.Item
                        className="shadow-md w-full"
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Please enter password",
                          },
                        ]}
                      >
                        <Input.Password
                          placeholder="Password"
                          className="shadow-md w-full"
                        />
                      </Form.Item>
                      <Form.Item
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Button
                          className="btn-bg w-72 shadow-xl"
                          size="large"
                          type="primary"
                          htmlType="submit"
                        >
                          Login
                        </Button>
                      </Form.Item>
                    </Form>
                  </>
                ),
              },
              {
                label: (
                  <span className="flex items-center justify-center gap-1">
                    <FaUserPlus />
                    Sign up
                  </span>
                ),
                key: 2,
                children: (
                  <>
                    <h1 className="text-2xl font-bold text-center text-[#001529] font-mono">
                      Sign up
                    </h1>

                    <Form
                      size="large"
                      layout="vertical"
                      initialValues={{
                        email: "",
                        password: "",
                      }}
                      className="flex items-center flex-col w-full mt-2"
                      onFinish={signUp}
                    >
                      <Form.Item
                        className="shadow-md w-full"
                        name="fName"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your first name",
                          },
                        ]}
                      >
                        <Input
                          placeholder="First Name"
                          className="shadow-md w-full"
                        />
                      </Form.Item>
                      <Form.Item
                        className="shadow-md w-full"
                        name="lName"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your last name",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Last Name"
                          className="shadow-md w-full"
                        />
                      </Form.Item>
                      <Form.Item
                        className="shadow-md w-full"
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Please enter email",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Email"
                          className="shadow-md w-full"
                        />
                      </Form.Item>
                      <Form.Item
                        className="shadow-md w-full"
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Please enter password",
                          },
                        ]}
                      >
                        <Input.Password
                          placeholder="Password"
                          className="shadow-md w-full"
                        />
                      </Form.Item>
                      <Form.Item
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Button
                          className="btn-bg w-72 shadow-xl flex items-center justify-center"
                          size="large"
                          type="primary"
                          htmlType="submit"
                          style={{
                            background: isLoading ? "#0a84f7a6" : "#001529",
                            opacity: isLoading ? "50%" : "100%",
                          }}
                        >
                          {isLoading ? <Loader /> : "Sign up"}
                        </Button>
                      </Form.Item>
                    </Form>
                  </>
                ),
              },
            ]}
          />
        </div>
        <div className="w-2/4 left flex flex-col gap-3 items-center justify-center rounded-lg">
          <img src="/login_svg-1.svg" alt="" />
          <span className="text-[#001529] font-semibold">
            &#169;Powered by WebJoni Team{" "}
            <span className="text-red-700">❤</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Login;
