import {useEffect} from 'react';
import { useNavigate } from 'react-router-dom'
// import { useSelector, useDispatch } from 'react-redux'
import { Layout, notification } from 'antd';
import Sidebar from '../components/dashboard/Sidebar';
import Header from '../components/dashboard/DashboardHeader'
import DashboardContent from '../components/dashboard/DashboardContent';

const DashboardLayout = () => {
    const navigate = useNavigate()
    // const dispatch = useDispatch()

    // const { isError, message } = useSelector((state) => state.auth)
    const user = localStorage.getItem('user')

    // useEffect(() => {
    //     if (isError) {
    //         notification.open({
    //             message: message,
    //             type: 'error',
    //             placement: 'top',
    //             style: {
    //                 zIndex: '999',

    //             }
    //         })
    //     }
    
    //     if (!user) {
    //       navigate('/')
    //     }
    // })    

    return (
        <>
            <Layout className='dashboardLayout'>
                <Sidebar />
                <Layout className="site-layout">
                    <Header />
                    <DashboardContent />
                </Layout>
            </Layout>
        </>
    )
}

export default DashboardLayout