import axios from "axios";

// const API_URL = 'https://kjcbackend.herokuapp.com/api'
const API_URL = 'https://saveme-server.vercel.app/api'

// Get user token from localStorage
// const token = JSON.parse(localStorage.getItem('user'))

// const config = {
//   headers: { Authorization: `Bearer ${token}` }
// }

// Add New Email
const addEmail = async (data) => {
  const response = await axios.post(`${API_URL}/postemail`, data)

  return response.data
}

// Get Single Email By Id
const getEmail = async (id) => {

  const response = await axios.get(`${API_URL}/email/${id}`)

  return response.data
}

// Get All Emails
const getEmails = async (userId) => {
  const response = await axios.get(`${API_URL}/emails/${userId}`)

  return response.data
}

// Update Email
const updateEmail = async (id, email) => {
  const response = await axios.put(`${API_URL}/updateemail/${id}`, email)

  return response.data
}


// Delete Email
const deleteEmail = async (id) => {

  const response = await axios.delete(`${API_URL}/deleteemail/${id}`)

  return response.data
}


const emailApi = {
  addEmail,
  getEmail,
  getEmails,
  updateEmail,
  deleteEmail,
}

export default emailApi