import { Button, Form, Input, Modal, Spin } from "antd";
import { Formik } from "formik";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addYoutube,
  getYoutube,
  updateYoutube,
} from "../redux/actions/youtubeAction";
import { reset } from "../redux/reducers/youtubeSlice";

const YoutubeModal = (props) => {
  const dispatch = useDispatch();
  const { youtube, singleYoutubeIsLoading } = useSelector(
    (state) => state.youtube
  );
  useEffect(() => {
    if (props?.data) {
      dispatch(getYoutube(props?.data?.key));
    }
  }, []);

  const handleSubmit = (values) => {
    if (props?.data) {
      dispatch(
        updateYoutube({
          id: props.data.key,
          youtube: values,
        })
      );
    } else {
      values.userId = JSON.parse(localStorage.getItem("user"));
      dispatch(addYoutube(values));
    }
    props.close();
  };
  if (singleYoutubeIsLoading) {
    return;
  }
  return (
    <Modal
      title={props?.data ? "Edit Youtube Channel" : "Add Youtube Channel"}
      open={props.show}
      footer={null}
      onCancel={props.close}
    >
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <Form
          size="large"
          layout="vertical"
          initialValues={{
            creatorName: youtube?.creatorName || "",
            channelName: youtube?.channelName || "",
            channelId: youtube?.channelId || "",
            channelUrl: youtube?.channelUrl || "",
            password: youtube?.password || "",
          }}
          onSubmit={handleSubmit}
          // name="basic"
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
          }}
          onFinish={handleSubmit}
        >
          <Form.Item
            style={{ width: "100%" }}
            name="creatorName"
            label="Creator Name"
            rules={[
              {
                required: true,
                message: "Please enter channel creator name",
              },
            ]}
          >
            <Input placeholder="Creator Name" style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            style={{ width: "100%" }}
            name="channelName"
            label="Channel Name"
            rules={[
              {
                required: true,
                message: "Please enter channel name",
              },
            ]}
          >
            <Input placeholder="Channel Name" style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            style={{ width: "100%" }}
            name="channelId"
            label="Channel Id"
            rules={[
              {
                required: true,
                message: "Please enter channel id",
              },
            ]}
          >
            <Input
              placeholder="Channel Id (i.e. example@gmail.com)"
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            style={{ width: "100%" }}
            name="channelUrl"
            label="Channel URL"
          >
            <Input placeholder="Channel URL" style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            style={{ width: "100%" }}
            name="password"
            label="Password"
          >
            <Input.Password placeholder="Password" style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Button
              className="btn-bg"
              size="large"
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default YoutubeModal;
