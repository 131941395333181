import { useState, useEffect } from "react";
import {
  ExclamationCircleOutlined,
  InteractionOutlined,
} from "@ant-design/icons";
import { Typography, Input, Space, Spin, Popconfirm, Tooltip, Table, Modal, Button } from "antd";
import { FaPenSquare, FaTrash, FaTrashAlt } from "react-icons/fa";
import FacebookModal from "../modals/FacebookModal";
const { Title } = Typography;
const { Search } = Input;

const Facebook = () => {
  const [showFacebookModal, setShowFacebookModal] = useState(false);
  const [facebookData, setFacebookData] = useState();
  const [searchedText, setSearchedText] = useState("");

  const handleEdit = (data) => {
    setFacebookData(data);
    setShowFacebookModal(true);
  };

  const handleSave = () => {
    setShowFacebookModal(true);
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to delete this facebook?",
      okText: "Delete",
      cancelText: "Cancel",
      okType: "danger",
      onOk: () => {
        // dispatch(deleteFacebook(id));
      },
    });
  };

  const columns = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      visible: true,
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return (
          String(record.name).toLowerCase().includes(value.toLowerCase())
        );
      }
    },
    {
      title: "Facebook",
      dataIndex: "facebook",
      key: "facebook",
    },
    {
      title: "Password",
      dataIndex: "password",
      key: "password",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space>
          <Button size="small" type="text" style={{ color: "blue" }}>
            <Tooltip title="edit">
              <FaPenSquare
                onClick={() => handleEdit(record)}
              />
            </Tooltip>
          </Button>
          <Button
            size="small"
            type="text"
            danger
            onClick={() => handleDelete(record.key)}
          >
            <Tooltip title="delete">
              <FaTrash />
            </Tooltip>
          </Button>
        </Space>
      ),
    },
  ]

  const data = [{
    no: 1,
    name: "Awais",
    facebook: "awais@gmail.com",
    password: "123"
  }]

  return (
    <div className="tableWrapper">
      <div className="categoryHeader">
        <Title level={2}>
          Facebooks
        </Title>
        <Space>
          <Search
            placeholder="Search facebook"
            allowClear
            size="large"
            onSearch={(value) => setSearchedText(value)}
            onChange={(e) => setSearchedText(e.target.value)}
            style={{
              width: 300,
            }}
            className="searchBar"
          />
          <Button
            className="btn-bg"
            // disabled={isLoading}
            type="primary"
            size="large"
            onClick={() => handleSave()}
          >
            Add Facebook
          </Button>
        </Space>

        {showFacebookModal && (
          <FacebookModal
            show={showFacebookModal}
            close={() => {
              setShowFacebookModal(false);
              setFacebookData(null);
            }}
            data={facebookData}
          />
        )}
      </div>
      <Table
        // loading={isLoading}
        columns={columns}
        dataSource={data}
        pagination={{ defaultPageSize: 5 }}
        className="table"
        scroll={{ x: 100 }}
      />
    </div>
  );
}

export default Facebook